import React from 'react';
import { IonButton } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import { sprintf, deepIsDefined, isDefined, isWebConfig, forwardTo } from '../../lib/utils';
import Layout from '../../components/layout';
import { Title, Spacer, NormalText, Subtitle, SmallText } from '../../components/common';
import Incrementer from '../../components/incrementer';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import BigNumber from '../../lib/bignumber';
import './index.css';

class ApplyPointsRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: this.getRedeemPointsMin(),
    };
  }

  getRedeemPointsMin = () => parseInt(getConfig().general.redeemPointsMin || 50);

  componentDidMount() {
    const points = Basket.getAppliedPoints();
    if (points > 0) {
      this.setState({ points });
    }
  }

  onIncrementerUpdate = (points) => {
    // from min to 0
    if (points < this.state.points && points < this.getRedeemPointsMin()) {
      points = 0;
    }
    // from 0 to min
    if (points > this.state.points && points < this.getRedeemPointsMin()) {
      points = this.getRedeemPointsMin();
    }
    this.setState({ points });
  };

  applyPoints = () => {
    Basket.applyPoints(this.state.points, this.props.availableBalance, () => {
      if (isWebConfig()) {
        forwardTo('/order');
      } else {
        forwardTo('/order-summary');
      }
    });
  };

  render() {
    const { __ } = this.props;
    let availableBalance = this.props.availableBalance;
    const { points } = this.state;
    // const formatedAmount = Basket._calculatePointsAppliedPrice(points, false, true)
    const step = getConfig().general.redeemPointsStep || 50;
    const basketTotalInCents =
      new BigNumber(Basket.getTotal()).times(100).toNumber() + Basket.getAppliedPoints();
    let limit = 0;
    if (!isDefined(availableBalance) && availableBalance === null) {
      availableBalance = 0;
    } else {
      limit = parseInt(availableBalance / step) * step;
    }
    // basket total: 5.2$ => 520 => 520/step(e.g. step=100)=5.2 => int(5.2) = 5 => 5 * step(e.g. step=100)
    // limit                    - represents available balance limit
    // pointsLimitBasedOnBasket - represents limit based on basket value
    // we will use lower value
    let pointsLimitBasedOnBasket = basketTotalInCents;
    if (pointsLimitBasedOnBasket > 0) {
      if (basketTotalInCents <= availableBalance) {
        // if user have enough points to cover entire basket total then we should let him to go over step
        limit = basketTotalInCents;
      } else {
        limit = availableBalance;
      }
    }

    return (
      <div className="apply-points-wrapper">
        <Subtitle>{__('Loyalty Points')}</Subtitle>

        <div className="apply-points-content box-wrapper" style={{ marginTop: '10px' }}>
          <div className="wrap-small-text">
            <NormalText className="primary-regular ">
              {__('Great News! You have')}{' '}
              <strong>
                {availableBalance} {__('loyalty points')}
              </strong>{' '}
              {__('available worth')}{' '}
              <strong>{Basket._calculatePointsAppliedPrice(availableBalance, false)}</strong>.{' '}
              {__('How many points would you want to redeem') + '?'}
            </NormalText>
          </div>
          <Spacer size={2} />
          {isWebConfig() ? (
            <Incrementer
              maxLimit={limit < pointsLimitBasedOnBasket ? limit : pointsLimitBasedOnBasket}
              onUpdate={this.onIncrementerUpdate}
              quantity={points}
              step={step}
              allowNegative={false}
              unit={__('Points')}
            />
          ) : (
            <Incrementer
              maxLimit={limit < pointsLimitBasedOnBasket ? limit : pointsLimitBasedOnBasket}
              onUpdate={this.onIncrementerUpdate}
              quantity={points}
              step={step}
              allowNegative={false}
            />
          )}
          <Spacer size={1} />
          {availableBalance < this.getRedeemPointsMin() && (
            <NormalText className="centered block danger-color">
              {__('You require')} {this.getRedeemPointsMin()} {__('points for redemption')}
            </NormalText>
          )}
          <div className="flex-min">
            <IonButton
              expand="block"
              color="secondary"
              disabled={
                points === 0
                  ? false
                  : availableBalance < this.getRedeemPointsMin() ||
                    parseInt(Basket.getSubTotal() * 100) < this.getRedeemPointsMin()
                  ? true
                  : false
              }
              onClick={() => this.applyPoints()}
            >
              {__('Redeem')} {points} {__('Loyalty Points')}
            </IonButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
  };
};

export const ApplyPoints = connect(mapStateToProps)(withTranslation(ApplyPointsRaw));

const ApplyPointsWrapped = (props) => (
  <Loading>
    <Layout color="transparent" hideSecondToolbar={true} headerTitle={props.__('Redeem Points')}>
      <div className='absolute-content scrollable-y'>
        <ApplyPoints {...props} />
      </div>
    </Layout>
  </Loading>
);

export default withTranslation(ApplyPointsWrapped);
