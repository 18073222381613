import React from 'react';
import { Title, NormalText, StrongText, Subtitle } from '../common';
import { isEmptyObject } from '../../lib/utils';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import './index.css';
import { getConfig } from '../../appConfig';

const ContentHeader = ({ __, deliveryOption, orderType, deliveryAddress, pickUpPoint, tableNumberHandler, orderSummary = false }) => {
  const formatDayName = (name) => {
		if (name.includes('Today')) {
			name = 'Today'.toLowerCase();
		} else if (name.includes('Tomorrow')) {
			name = 'Tomorrow'.toLowerCase();
		} else {
			name = Basket.getOrderDate();
		}
		return name;
	};

  let dateName = formatDayName(
    moment(Basket.getOrderDate(), 'dddd Do MMMM').calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
    }),
  );
  if (!orderType || orderType === '') {
    return null;
  }
  if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
    return (
      <div className="box-content order-header-content">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--ion-color-secondary)"
            className="icon icon-tabler icon-tabler-map"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
            <line x1="9" y1="4" x2="9" y2="17"></line>
            <line x1="15" y1="7" x2="15" y2="20"></line>
          </svg>
          <Subtitle>{Basket.getRestauranName()}</Subtitle>
        </div>
      </div>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
    return (
      <div className="box-content order-header-content">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--ion-color-secondary)"
            className="icon icon-tabler icon-tabler-map"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
            <line x1="9" y1="4" x2="9" y2="17"></line>
            <line x1="15" y1="7" x2="15" y2="20"></line>
          </svg>
          <Subtitle>{Basket.getRestauranName()}</Subtitle>
        </div>
        <div>
          <NormalText>{__('Delivery to')}</NormalText>
        </div>
        <StrongText>
          {deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode} {dateName} {__('at')} {Basket.getOrderTime()}
        </StrongText>
      </div>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
    return (
      <div className="box-content order-header-content">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--ion-color-secondary)"
            className="icon icon-tabler icon-tabler-map"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
            <line x1="9" y1="4" x2="9" y2="17"></line>
            <line x1="15" y1="7" x2="15" y2="20"></line>
          </svg>{' '}
          <Subtitle>{Basket.getRestauranName()}</Subtitle>
        </div>
        <div>
          <NormalText>{__('Table number')}</NormalText>
          <StrongText className="bold">{Basket.getTableNumber()}</StrongText>
        </div>
      </div>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'scheduled-collection') {
    return (
      <div className="box-content order-header-content">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--ion-color-secondary)"
            className="icon icon-tabler icon-tabler-map"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
            <line x1="9" y1="4" x2="9" y2="17"></line>
            <line x1="15" y1="7" x2="15" y2="20"></line>
          </svg>{' '}
          <Subtitle>{Basket.getRestauranName()}</Subtitle>
        </div>
        <div>
          <NormalText>{__('Collection time')}</NormalText>
        </div>
        <StrongText className="bold">
          {dateName} {'at'} {Basket.getOrderTime('h:mm a')}
        </StrongText>
      </div>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-at-counter') {
    return (
      <div className="box-content order-header-content">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--ion-color-secondary)"
            className="icon icon-tabler icon-tabler-map"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
            <line x1="9" y1="4" x2="9" y2="17"></line>
            <line x1="15" y1="7" x2="15" y2="20"></line>
          </svg>{' '}
          <Subtitle>{Basket.getRestauranName()}</Subtitle>
        </div>
      </div>
    );
  } else {
    return (
      <div className="box-content order-header-content">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--ion-color-secondary)"
            className="icon icon-tabler icon-tabler-map"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
            <line x1="9" y1="4" x2="9" y2="17"></line>
            <line x1="15" y1="7" x2="15" y2="20"></line>
          </svg>

          <Subtitle>{Basket.getRestauranName()}</Subtitle>
        </div>
        <div>
          <NormalText>{__('Collection time')}</NormalText>
          <StrongText className="bold">{getConfig()?.flags?.hasCollectionASAP?'ASAP':Basket.getOrderTime('h:mm a')}</StrongText>
        </div>
      </div>
    );
  }
};

export default ContentHeader;
