import React from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonButton,
} from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { setDeliveryOption } from '../../store/actions';
import { forwardTo, getSingleDeliveryOption, isDefined } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import Basket from '../../lib/basket';
import api from '../../lib/api';
import {  SmallText, StrongText, Title } from '../../components/common';
import './index.css';
import { CLEAR_GIFT_VOUCHER_DATA } from '../../store/constants';

class DeliveryOptions extends React.Component {
  state = {
    option: null,
  };

  setDeliveryOption = () => {
    Basket.reset();
    this.props.dispatch({ type: CLEAR_GIFT_VOUCHER_DATA });
    const {option} = this.state
    this.props.dispatch(setDeliveryOption(option));
    forwardTo(option.route);
  };

  checkForSingleDeliveryOption = () => {
    const singleDeliveryOption = getSingleDeliveryOption();
    if (singleDeliveryOption) {
      this.setState({option:singleDeliveryOption},()=>{
        this.setDeliveryOption();

      })
    }
  };

  componentDidMount() {
    const { defaultMenuId } = this.props;
    Basket.reset();
    this.checkForSingleDeliveryOption();
    api.getDefaultMenu(defaultMenuId).then((res) => {
      this.props.dispatch({
        type: 'SET_RESTAURANT_PROP',
        key: 'defaultMenu',
        value: { ...res, menuName: `${res.menuName} Default` },
        merge: true,
      });
    });
  }

  componentDidUpdate() {
    this.checkForSingleDeliveryOption();
  }

  selectOption(option) {
    this.setState({ option: option });
  }

  render() {
    const { __ } = this.props;
    const { option } = this.state;
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];
    return (
      <Layout hideSecondToolbar={true} color="transparent" noPadding={true}>
        <IonCard className="delivery-options-card">
          <IonCardHeader>
            <Title className="delivery-options-title">{__('Start New Order')}</Title>
          </IonCardHeader>
          <IonCardContent className="delivery-options-content">
            <IonList lines="none" className=' box-wrapper dark-text'>
              {delivery.map((d, index) => (
                <>
                  {!d.isRemoved && (
                    <IonItem
                    className='default-padding'
                      key={`option${index}`}
                      disabled={d.isDisabled}
                      onClick={() => this.selectOption(d)}
                    >
                      <IonLabel>
                        <StrongText>{__(d.label)}</StrongText>
                        <SmallText className="block">{__(d.description)}</SmallText>
                      </IonLabel>
                      <IonCheckbox
                        checked={option && d.id === option.id}
                        slot="start"
                        color="secondary"
                      />
                    </IonItem>
                  )}
                </>
              ))}
            </IonList>
          </IonCardContent>
          <div className="delivery-options-button">
            <IonButton
              disabled={!option}
              expand="block"
              color="secondary"
              onClick={() => this.setDeliveryOption()}
            >
              {__('Start New Order')}
            </IonButton>
          </div>
        </IonCard>
      </Layout>
    );
  }
}

const stateToProps = (state) => ({
  deliveryOption: state.orders.deliveryOption,
  defaultMenuId: state.common.defaultMenuId,
});

export default connect(stateToProps)(withTranslation(DeliveryOptions));
