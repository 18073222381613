import React from 'react';
import { IonIcon } from '@ionic/react';
import { forwardTo } from '../../lib/utils';
import { Subtitle } from '../../components/common';
import './index.css';

const PromoBar = ({ icon, onDismiss, vouchers }) => {
  const formatCapitalizePromoText = (text) => {
    if (typeof text !== 'string') {
      return '';
    }
    let str = text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
    return str;
  };

  return vouchers && vouchers.length > 0 && vouchers[0].reward ? (
    <div className='promo-bar-wrapper'>
      <div className="promo-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
      <div
        className={'promo-bar'}
        onClick={() => forwardTo('/loyalty', { openVoucherModal: vouchers[0].id })}
      > 
        <IonIcon color='secondary' slot="icon-only" icon={icon} />
        <Subtitle color='secondary' className="promo-bar-body ellipsis">
          {formatCapitalizePromoText(vouchers[0].reward.small_print)}
        </Subtitle>
      </div>
    </div>
  ) : null;
};

export default PromoBar;
