import React from 'react';
import { IonButton, IonInput, IonToggle, IonItem, IonLabel, IonList, IonAlert } from '@ionic/react';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { registerRequest, setModal, setSysLocale, setRegisterForm } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import {
  FieldError,
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
  Subtitle,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import { Device } from '@capacitor/device';
import './index.css';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';

class CreateAccounf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      password: '',
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleGuestReigister = this.handleGuestReigister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      first_name: { type: 'text', required: false },
      mobile: { type: 'text', required: false },
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });

    // const { dispatch } = this.props;
    // this.setState({})
    // dispatch(setRegisterForm(key, val));
    // if (key === 'accepted_terms_and_conditions') {
    //     let formErrors = { ...this.state.formErrors };
    //     formErrors.accepted_terms_and_conditions = undefined;
    //     this.setState({ formErrors });
    // }
  }

  handleRegister() {
    const { registerFormData, guestUserData } = this.props;
    const {
      guest_email,
      guest_first_name,
      guest_mobile,
      guest_accepted_terms_and_conditions,
    } = guestUserData;
    registerFormData.email = guest_email;
    registerFormData.first_name = guest_first_name;
    registerFormData.mobile = guest_mobile;
    registerFormData.accepted_terms_and_conditions = guest_accepted_terms_and_conditions;
    registerFormData.password = this.state.password;
    let formErrors = validateForm(this.formConfig, registerFormData);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      this.props.dispatch(registerRequest({ referrer: '/order' }));
    }
  }
  handleGuestReigister() {
    const { registerFormData, guestUserData } = this.props;
    const timestamp = new Date().getTime();
    const {
      guest_email,
      guest_first_name,
      guest_mobile,
      guest_accepted_terms_and_conditions,
    } = guestUserData;
    registerFormData.email = `${timestamp}_${guest_email}`;
    registerFormData.first_name = guest_first_name;
    registerFormData.mobile = guest_mobile;
    registerFormData.accepted_terms_and_conditions = guest_accepted_terms_and_conditions;
    registerFormData.guest = true;
    registerFormData.password = timestamp;
    this.props.dispatch(registerRequest({ referrer: '/checkout' }));
  }
  componentDidMount() {
    const { loggedIn } = this.props.auth;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path);
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');
  calculateOrderPoints = () => {
    let calculatedPoints = Math.floor(
      Basket.getTotal() * getConfig().api_config.points_value_percentage,
    );
    return calculatedPoints.toFixed(0);
  };
  render() {
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const { password } = this.state;
    return (
      <Loading transparent>
        <Layout headerWithTitle={true} hideSecondToolbar={true} headerTitle={__('Create Account')} color="transparent">
          <div className="absolute-content scrollable-y">
            <Title className='web-only'>{__('Create Account')}</Title>
            <NormalText>{__('It’s not too late')}...</NormalText>
            <Spacer />
            <NormalText>{__('You can still earn')}</NormalText>{' '}
            <StrongText className=''>{this.calculateOrderPoints()}{' '}{'loyalty points'}{' '}</StrongText>{' '}
            <NormalText>
              {__('for this order, simply complete the form below to sign up')}
            </NormalText>
            <Spacer />
            <IonList>
              <Subtitle className='bold'>{this.props.guestUserData.guest_email}</Subtitle>
              <Spacer size={1} />
              <div className='input-field-container'>
                <NormalText>{__('Password')}</NormalText>
                <IonItem lines='none' className='input-field-wrapper'>                <PasswordInput
                  placeholder={__('Enter your password') + ' *'}
                  onIonChange={(e) => this.handleInput('password', e.target.value)}
                  value={password}
                />
                </IonItem>
              </div>
              <FieldError className="field-error" value={__(this.state.formErrors.password)} />
            </IonList>
            <Spacer />
            <div className="top-medium">
              <IonButton
                expand="block"
                color="secondary"
                className="register-button uppercase okx-font-secondary"
                onClick={() => this.handleRegister()}
              >
                {__('Register & Earn')}
              </IonButton>
              <IonButton expand="block" fill='clear' color="secondary" onClick={() => this.handleGuestReigister()}>
                {__('No thanks')}
              </IonButton>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData, guestUserData } = state.profile;
  const { navConfig } = state.common;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    navConfig,
    guestUserData,
  };
};

export default connect(stateToProps)(withTranslation(CreateAccounf));
