import React from 'react';
import { IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonButton, IonIcon } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
// import { arrowForward } from 'ionicons/icons'
import { NormalText, SmallText, StrongText, Subtitle } from '../../components/common';
import NoDataSecond from '../../components/noDataSecond';
import Basket from '../../lib/basket';
import earnedArrow from '../../assets/images/earned-arrow.svg';
import redeemedArrow from '../../assets/images/redeemed-arrow.svg';
import { getConfig } from '../../appConfig';
import { forwardTo, isDefined } from '../../lib/utils';
import { showToast } from '../../store/actions';
import { repeatOutline } from 'ionicons/icons';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const getDeliveryLabel = (deliveryLabel) => {
  switch (deliveryLabel) {
    case 'Scheduled Delivery':
      return 'Delivery Order';
    case 'Order for Delivery':
      return 'Delivery Order';
    case 'Click & Collect':
      return 'Collection Order';
    default:
      return deliveryLabel;
  }
};

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history,restaurants,dispatch, noTransactionText, ...rest }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };
    const orderAgain = (past_orders, option) => {
      const selectedRestaurant = restaurants.filter(
        (restaurant) => restaurant.id == past_orders.restaurant_id,
      );
      if (selectedRestaurant.length > 0) {
        if (option.id == 'collection') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'collection');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_collection_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {
            Basket.reset();
            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
        if (option.id == 'table') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'table');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_table_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {
            Basket.reset();
            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
        if (option.id == 'charter-delivery') {
          const orderType = getConfig().delivery.filter((el) => el.id == 'charter-delivery');
          const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
          if (
            selectedRestaurant[0].can_charter_delivery_order &&
            selectedRestaurant[0].is_published &&
            !orderTypeDisabled
          ) {

            Basket.reset();
            Basket.setOrderType('charter-delivery');

            Basket.setDeliveryOption(option);
            forwardTo(option.route, { selectedRestaurant: past_orders });
          } else {
            dispatch(showToast('This restaurant is currently not accepting orders.', 'warning'));
          }
        }
      } else {
        dispatch(showToast('This restaurant is no longer accepting orders.', 'warning'));
      }
    };
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];
    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            <div className='no-transaction-history'>
              <NormalText>{noTransactionText? __(noTransactionText) : __("Looks like you haven't earned any loyalty yet. Once you do your updates will appear here.")}</NormalText>
            </div>
          ) : (
            <IonGrid className='box-wrapper '>
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, location_name, label, item } = i;
                // const splitedLabel = label.split('#').splice(1).join('')
                const labelLocationName = label.split('#').splice(0).join('');
                let orderLabel = Basket.getOrderType(i.item);
                let option = (delivery || []).find((d) => d.id.includes(orderLabel));
                let status = item && item.status ? item.status : '';
                let orderId = i && i.item ? ' #' + i.item.id : '';
                return ((status === 'CREATED' ||
                status === 'created' || status === 'NEW' ||
                status === 'new') && !item?.is_gift) ? null :(
                  <IonRow key={index} className="history-item dark-text">
                    <IonCol size={type == 'order' ? '6' : '8'} onClick={() => handleRowClick(item)} >
                      {!item?.is_gift &&
                        <SmallText
                          color="primary"
                          className={`ellipsis block ${type === 'order' && 'order-location-name'
                            } history-small-text `}
                        >
                          {type === 'order' ? labelLocationName : location_name}
                        </SmallText>
                      }
                      {!item?.is_gift ?
                        <StrongText className='block roboto-slab transaction-label'>
                          {type === 'order' ? (option ? __(getDeliveryLabel(option.label)) : '') : __(label)}
                        </StrongText>
                        :
                        <StrongText className='block roboto-slab transaction-label'>
                          {__('eGift Voucher')}
                        </StrongText>
                      }

                      <SmallText color="primary" className="ellipsis block history-small-text">
                        {item
                          ? transaction_date
                          : Basket.getDate(transaction_date).format('DD MMMM YYYY [at] h:mm a')}
                      </SmallText>
                    </IonCol>
                    {type === 'order' ? (
											!item?.is_gift && (
												<IonCol
													style={{
														textAlign: 'end',
													}}
													className="order-button-col-width"
												>
													<IonButton color="secondary" className="small-btn reorder-button" onClick={() => orderAgain(item, option)}>
														<IonIcon color="white" icon={repeatOutline}></IonIcon>
														{__('Reorder')}
													</IonButton>
												</IonCol>
											)
										) : (
											<IonCol style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-end' }}>

                        <div className='transaction-points-wrapper'>
                          <Subtitle className={stamp_power < 0 ? 'danger-color' : 'secondary-color'}>
                            {stamp_power < 0 ? '-' : '+'}{Math.abs(stamp_power)}
                          </Subtitle>
                          <SmallText className='bold'>{__('points')}</SmallText>
                        </div>
                      </IonCol>
										)}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
