import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import {
  IonButton,
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonCheckbox,
} from '@ionic/react';
import Layout from '../../components/layout';
import {
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  passedTheOrder,
} from '../../store/restaurants/actions';
import mobiscroll from '@mobiscroll/react';

import { loading, setCommonModal, showToast } from '../../store/common/actions';
import { FieldError, NormalText, Sectiontitle, SmallText, Spacer, StrongText, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { isDefined, checkForDeliveryOption, isObject, isArray, getDistance, deepCopy, forwardTo, parseAllergenData, goBack, getDefaultRoute } from '../../lib/utils';
import moment from '../../lib/moment';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import Mobiscroll from '../../components/mobiscroll';
import { setDeliveryOption } from '../../store/actions';
import './index.css';
import NoData from '../../components/noData';
import api from '../../lib/api';
import { SET_RESTAURANT_PROP } from '../../store/constants';
import { getConfig } from '../../appConfig';

const { SelectOption } = Mobiscroll;
const isWeb = () => Capacitor.getPlatform() === 'web';

const createMomentFromTime = (time = '') => {
  const parsedTime = time.split(':');
  if (parsedTime.length !== 2 || time === '') {
    return null;
  }
  let hour = parseInt(time.split(':')[0]);
  let minutes = parseInt(time.split(':')[1]);
  return moment().hours(hour).minutes(minutes);
};

const toWhichSegmentTimeBelongs = (time, segments = []) => {
  let timeIsInSegment = -1;
  segments.forEach((segment, index) => {
    const { start, end } = segment;
    const targetTime = createMomentFromTime(time);
    const segmentStart = createMomentFromTime(start);
    const segmentEnd = createMomentFromTime(end);
    if (targetTime.isSameOrAfter(segmentStart) && targetTime.isSameOrBefore(segmentEnd)) {
      timeIsInSegment = index;
    }
  });
  return timeIsInSegment;
};

const isTimeInSegment = (time, segments = []) => {
  return toWhichSegmentTimeBelongs(time, segments) !== -1;
};

const addSegment = (start, end, segments = [], date) => {
  let updatedSegments = [...segments];
  const dayNumber = 'w' + date.day();
  const newSegment = { d: dayNumber, start: start, end: end };

  // check previously added segment. maybe some of them are handled with new added segment
  const oldSegments = [...segments];
  oldSegments.forEach((oldSegment, index) => {
    if (
      isTimeInSegment(oldSegment.start, [newSegment]) &&
      isTimeInSegment(oldSegment.end, [newSegment])
    ) {
      updatedSegments = removeSegment(index, updatedSegments);
    }
  });
  return [...updatedSegments, newSegment];
};

const updateSegment = (segmentIndex, propName, value, segments = []) => {
  let updatedSegments = [...segments];
  if (updatedSegments && updatedSegments[segmentIndex]) {
    updatedSegments[segmentIndex][propName] = value;
  }
  return updatedSegments;
};

const removeSegment = (segmentIndexForRemove, segments = []) =>
  [...segments]
    .map((segment, index) => (index === segmentIndexForRemove ? null : segment))
    .filter((segment) => isDefined(segment));

const parseTimesJson = (json = [], date) => {
  let parsed_json = [];
  const dayNumber = 'w' + date.day();

  // use old logic for 'json_time_selector' json (without: menuId and availabity)
  if (json.length > 0 && !json[0].menuId) {
    return json;
  }

  json.forEach((menu) => {
    (menu.availability || [])
      .filter((i) => i.d === dayNumber)
      .forEach((time) => {
        const { start, end } = time;
        if (parsed_json.length === 0) {
          // add first available time
          parsed_json = addSegment(start, end, parsed_json, date);
        } else {
          if (!isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
            // case 1: start and end dont belong to any other segment
            parsed_json = addSegment(start, end, parsed_json, date);
          } else if (isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
            // case 2: start belong to some segment and end dont bolong to any segment
            const segmentIndex = toWhichSegmentTimeBelongs(start, parsed_json);
            parsed_json = updateSegment(segmentIndex, 'end', end, parsed_json);
          } else if (!isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
            // case 3: end belong to some segment and start dont bolong to any segment
            const segmentIndex = toWhichSegmentTimeBelongs(end, parsed_json);
            parsed_json = updateSegment(segmentIndex, 'start', start, parsed_json);
          } else if (isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
            // case 4: and start and end belongs to some segment
            const startSegmentIndex = toWhichSegmentTimeBelongs(start, parsed_json);
            const endSegmentIndex = toWhichSegmentTimeBelongs(end, parsed_json);
            if (parsed_json && parsed_json[startSegmentIndex] && parsed_json[endSegmentIndex]) {
              const newStartTime = parsed_json[startSegmentIndex].start;
              const newEndTime = parsed_json[endSegmentIndex].end;

              if (startSegmentIndex !== endSegmentIndex) {
                parsed_json = addSegment(newStartTime, newEndTime, parsed_json, date);
                parsed_json = removeSegment(startSegmentIndex, parsed_json);
                parsed_json = removeSegment(endSegmentIndex, parsed_json);
              }
            }
          }
        }
      });
  });

  // sort times by 'start' time
  return parsed_json.sort((a, b) => {
    const aStart = createMomentFromTime(a.start);
    const bStart = createMomentFromTime(b.start);
    return bStart.isSameOrBefore(aStart) ? 1 : -1;
  });
};

/*
menusForLocation: [{menuName: "Winter Menu 2014", ikentooMenuId: 37082747671397}, ...]
pickTime: 12:45
json_time_selector: [{
  "menuId": 37082747671609,
  "availability": [
    { "d": "w1", "start": "07:15", "end": "15:45" },
    { "d": "w2", "start": "07:15", "end": "15:45" },
    { "d": "w3", "start": "07:15", "end": "15:45" },
    { "d": "w4", "start": "07:15", "end": "15:45" },
    { "d": "w5", "start": "07:15", "end": "15:45" }
  ]
}, ... ]
*/
export const getMenusForSelectedTime = (
  menusForLocation = [],
  pickTime,
  json_time_selector = [],
) => {
  if (isObject(menusForLocation) && !isArray(menusForLocation)) {
    menusForLocation = [menusForLocation];
  }
  if (pickTime === 'asap') {
    return menusForLocation;
  } else {
    return menusForLocation.filter((menu) => {
      const ikentooMenuId = menu.ikentooMenuId;

      if (json_time_selector.length > 0 && json_time_selector[0].menuId && pickTime) {
        const target_menu = json_time_selector.find((i) => i.menuId == ikentooMenuId);
        if (
          target_menu &&
          isTimeInSegment(
            pickTime,
            target_menu.availability.filter((i) => i.d === 'w' + moment().day()),
          )
        ) {
          return true;
        }
      } else {
        // handle old json_time_selector (without menuId and availability)
        return false;
      }

      return false;
    });
  }
};
export const checkSnoozedTimes = (store, order_type) => {
  if (!store) {
    return null;
  }

  if (order_type !== 'table' && order_type !== 'gift-vouchers') {
    let snoozed_disabled = [];
    if (store.hasOwnProperty('snoozed_times')) {
      const snoozed_times = store.snoozed_times;
      snoozed_disabled.push(
        ...(snoozed_times[order_type] ? snoozed_times[order_type] : snoozed_times),
      );
    }
    if (store.hasOwnProperty('disabled_times')) {
      const disabled_times = store.disabled_times;
      snoozed_disabled.push(
        ...(disabled_times[order_type] ? disabled_times[order_type] : disabled_times),
      );
    }
    return snoozed_disabled;
  } else {
    return [];
  }
};

export const formatDataForTime = (
  store,
  minDate,
  selectedRestaurant,
  isCharterDelivery,
  isTableOrder,
  snoozedTimes,
) => {
  let timesInBetween = [];
  let storeTimes = [];
  const charterDeliveryPeriod =
    store && store.charter_delivery_order_slot_interval_mins
      ? store.charter_delivery_order_slot_interval_mins
      : 30;
  const clickAndCollectPeriod =
    store && store.order_slot_interval_mins ? store.order_slot_interval_mins : 5;
  const minDT = minDate.format('HH:mm');
  const date = moment(minDate);
  const dow = 'w' + date.day();
  const period = isCharterDelivery ? charterDeliveryPeriod : clickAndCollectPeriod;
  const collection_minutes = [];
  let minutes = -period;

  while (minutes < 60) {
    minutes += period;
    if (minutes < 60) {
      collection_minutes.push(minutes);
    }
  }
  if (store) {
    parseTimesJson(
      isCharterDelivery
        ? store.charter_delivery_times_json
        : isTableOrder
          ? store.table_json_time_selector
          : store.json_time_selector,
      date,
    ).forEach((time) => {
      if (time.d === dow) {
        storeTimes.push({ ...time });
      }
    });
  }

  function makePickerText(times, j, i) {
    let collectionMinutes = parseInt(times[j]) < 10 ? '0' + times[j] : times[j];
    return {
      text: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
      value: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
    };
  }

  function returnTimesInBetween(start, end, cnt) {
    let startH = parseInt(start.split(':')[0]);
    let startM = parseInt(start.split(':')[1]);
    let endH = parseInt(end.split(':')[0]);
    let endM = parseInt(end.split(':')[1]);
    let minTimeStart = parseInt(minDT.split(':')[0]);
    let minTimeEnd = parseInt(minDT.split(':')[1]);
    let c = collection_minutes.filter((cm) => cm >= startM);
    let cm = collection_minutes.filter((cm) => cm <= endM);
    let startHH = startH;
    if (startHH <= minTimeStart) {
      startHH = minTimeStart;
    }

    for (let i = startHH; i <= endH; i++) {
      if (startH === i) {
        for (let j = 0; j < c.length; j++) {
          if (c[j] >= minTimeEnd && cnt === 0 && startH <= minTimeStart) {
            timesInBetween.push(makePickerText(c, j, i));
          } else if (cnt !== 0) {
            timesInBetween.push(makePickerText(c, j, i));
          } else if (startH > minTimeStart) {
            timesInBetween.push(makePickerText(c, j, i));
          }
        }
      } else if (endH === i) {
        if (minTimeStart === i) {
          for (let j = 0; j < cm.length; j++) {
            if (cm[j] >= minTimeEnd) {
              timesInBetween.push(makePickerText(cm, j, i));
            }
          }
        } else {
          for (let j = 0; j < cm.length; j++) {
            timesInBetween.push(makePickerText(cm, j, i));
          }
        }
      } else {
        if (i === minTimeStart) {
          let collection_m = collection_minutes.filter((cm) => cm >= minTimeEnd);
          for (let j = 0; j < collection_m.length; j++) {
            timesInBetween.push(makePickerText(collection_m, j, i));
          }
        } else {
          for (let j = 0; j < collection_minutes.length; j++) {
            timesInBetween.push(makePickerText(collection_minutes, j, i));
          }
        }
      }
    }

    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (
      timesInBetween &&
      timesInBetween.length > 1 &&
      timesInBetween[0] &&
      timesInBetween[0].text === 'CLOSED'
    ) {
      timesInBetween.shift();
    }
    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (isDefined(store) && timesInBetween.length === 0) {
      timesInBetween.push({ text: 'CLOSED', value: null });
    }
    return timesInBetween;
  }
  storeTimes.forEach((storeT, i, arr) => {
    returnTimesInBetween(storeT.start, storeT.end, i);
    let minH = parseInt(minDT.split(':')[0]);
    let minM = parseInt(minDT.split(':')[1]);
    let endTimeH = parseInt(storeT.end.split(':')[0]);
    let endTimeM = parseInt(storeT.end.split(':')[1]);
    let minTime = date.hours(minH).minutes(minM);
    let timeEnd = date.hours(endTimeH).minutes(endTimeM);
    if (i < arr.length - 1 && arr.length > 0 && moment(minTime).isSameOrBefore(timeEnd)) {
      timesInBetween.push({ text: 'CLOSED', value: null });
    }
  });

  //remove 'CLOSED' label if that is first time
  if (
    timesInBetween &&
    timesInBetween.length > 1 &&
    timesInBetween[0] &&
    timesInBetween[0].text === 'CLOSED'
  ) {
    timesInBetween.shift();
  }
  if (timesInBetween.length === 0 && selectedRestaurant) {
    timesInBetween.push({ text: 'CLOSED', value: null });
  }
  return timesInBetween.map((time) => ({
    ...time,
    disabled: snoozedTimes.length > 0 && snoozedTimes.includes(time.text),
    html: `<span className='${snoozedTimes.length > 0 && snoozedTimes.includes(time.text) ? 'snoozed' : ''
      }'>${time.text}</span>`,
  }));
};


class ClickAndCollect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRestaurant: null,
      pickTime: null,
      selectedTimeOption: null,
      error: '',
      showRestaurants: false,
      showTime: false,
      isLocationAllowed: false,
      orderPassed: false,
      continueButtonClicked: false
    };
  }

  componentDidMount() {
    const { restaurants } = this.props;
    Basket.setOrderType('collection');
    if (this.props?.location?.state?.selectedRestaurant) {
      const selectedRestaurant = restaurants.find((restaurant) => restaurant.id === this.props?.location?.state?.selectedRestaurant?.restaurant_id)

      if(selectedRestaurant.opened){
        this.setState({ selectedRestaurant }, () => {
          Basket.setRestaurant(
            this.state.selectedRestaurant
          );
          if (!getConfig()?.flags?.hasCollectionASAP) {
            this.setState({ showTime: true })
          }
          Basket.setServicePercentage(0);
        })
      }
   
    }
    const deliveryOption = checkForDeliveryOption(Basket.getDeliveryOption(), '/click-and-collect');
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
    if (isWeb()) {
      this.position();
    }
  }

  selectRestaurant = (data) => {
    const { restaurants, profile } = this.props;

    this.setState(
      { selectedRestaurant: data, showRestaurants: false, showTime: !getConfig()?.flags?.hasCollectionASAP, pickTime: null },
      async () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === data.id));
        Basket.setServicePercentage(0)
        Basket.setCollectionTime(null);
        Basket.setOrderType('collection');

        if (getConfig()?.flags?.hasCollectionASAP) {
          const currentDT = moment();
          let minDT = currentDT;
          if (data && isDefined(data.order_slot_lead_time)) {
            minDT.add(data.order_slot_lead_time, 'minutes');
          }
          const snoozedTimes = checkSnoozedTimes(data, 'collection');
          const timePickerOptions = formatDataForTime(
            data,
            minDT,
            data?.id,
            false,
            false,
            snoozedTimes
          );
          const availableTimes = timePickerOptions.filter(el => !el.disabled)
          if (availableTimes.length > 0) {
            await this.changeTime(availableTimes[0].value, minDT)
            this.chooseMenusForLocation()
          } else {
            this.props.dispatch(
              showToast(
                'Restaurant is not accepting orders at this time.',
                'warning',
              ),
            );
          }

        }
      },
    );

  };

  changeTime = (selectedTime, minDT) => {
    if (selectedTime && minDT) {
      let h = parseInt(selectedTime.split(':')[0]);
      let m = parseInt(selectedTime.split(':')[1]);
      const formattedDT = moment(minDT).hours(h).minutes(m);
      this.setState({ pickTime: selectedTime }, () => {
        Basket.setCollectionTime(formattedDT);
        Basket.setOrderType('collection');
      });
    } else {
      this.setState({ pickTime: null });
    }
  };

  componentDidUpdate(prevProps) {
    checkForDeliveryOption(this.props.deliveryOption, '/click-and-collect');
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
        if (this.state.orderPassed) {
          this.continueOnMenu(this.props.ikentooMenusForLocation[0].ikentooMenuId);
        }
      }
    }
  }

  setPickTime = (inst, minDT) => {
    if (inst && inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
    } else {
      this.setState({ pickTime: null });
    }
  };

  continueOnMenu = (ikentooMenu) => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant, pickTime } = this.state;
    this.setState({ continueButtonClicked: true })
    if (selectedRestaurant && (ikentooMenu || selectedIkentooMenu)) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );

      if (pickTime === 'asap') {
        Basket.setCollectionTime(null);
      }
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu || ikentooMenu, businessLocationId));
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
      this.setState({ continueButtonClicked: false })

    } else {
      this.setState({ continueButtonClicked: false })
      this.setState({ error: 'Please select location menu' });
    }
  };
  getMenuForReorder = (menus, reorderItems) => {
    let menuForReorder = [];
    for (let i = 0; i < menus.length; i++) {
      let foundItems = 0;
      const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));
      menus[i].flattenMenuItems = items;
      if (items.length > 0) {
        for (let j = 0; j < reorderItems.length; j++) {
          let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku || i.largeSku === reorderItems[j].item.sku);
          if (foundItem) {
            foundItems = foundItems + 1;
          }
        }
      }
      menus[i].foundItems = foundItems;
      menuForReorder.push(menus[i]);
    }
    menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
    if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
      return this.filterMenu(menuForReorder[0])
    } else {
      return false;
    }
  };
  filterIkentooMenuItems = (items) => {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (item.sku && Basket.isProductJustEnabled(item)) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  };

  filterMenu = (menu) => {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  };
  chooseMenusForLocation = async () => {
    const { restaurants, dispatch } = this.props;
    const { selectedRestaurant, pickTime } = this.state;
    this.setState({ continueButtonClicked: true }, async () => {
      Basket.setPassedOrder(true);
      if (selectedRestaurant && pickTime) {
        const choosenRestaurant = restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurant.id,
        );
        const businessLocationId = choosenRestaurant.business_location_id;
        let cutoffTimeRes = moment().unix();
        Basket.setCutoffTime(cutoffTimeRes);
        if (this.props.location.state && this.props.location.state.selectedRestaurant) {
          let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);

          ikentooMenusForLocation = getMenusForSelectedTime(
            ikentooMenusForLocation,
            pickTime,
            choosenRestaurant.json_time_selector,
          );
          if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
            let ikentooMenus = [];
            try {
              for (let i = 0; i < ikentooMenusForLocation.length; i++) {
                ikentooMenus.push(
                  await api.getIkenooMenu(ikentooMenusForLocation[i].ikentooMenuId, businessLocationId),
                );
              }
            } catch (error) {
              this.setState({ error: 'Get restaurant menu error.' });
            }
            const reorderItems = this.props.location.state.selectedRestaurant.items;
            const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
            if (menu) {
              dispatch({
                type: SET_RESTAURANT_PROP,
                key: 'ikentooMenu',
                value: menu,
              });
              if (menu.flattenMenuItems.length > 0) {
                let validationStatus = { notBasketEmpty: false, validationError: false };
                reorderItems.map((newBasketItem, index) => {
                  let foundItem = menu.flattenMenuItems.find((i) => i.sku === newBasketItem.item.sku || i.largeSku === newBasketItem.item.sku);
                  if (foundItem && Basket.isProductEnabled(foundItem)) {
                    if (newBasketItem.selectedChoices[0]) {
                      newBasketItem.selectedChoices[0] = newBasketItem.selectedChoices[0].filter(
                        (el) => Basket.isProductEnabled(el),
                      );
                    }
                    Basket.addToBasket(newBasketItem);
                    let item = newBasketItem.item;
                    let allergens = this.props.allergens;
                    let profile = this.props.profile;
                    let newArr = parseAllergenData(profile, item, allergens);
                    let allergensCodes =
                      newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                        ? newBasketItem.item.itemRichData.allergenCodes
                        : [];
                    if (allergensCodes.length > 0) {
                      let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                      Basket.setAllergen(allergensData);
                    }
                    validationStatus.notBasketEmpty = true;
                  } else {
                    validationStatus.validationError = true;
                  }
                });
                if (validationStatus.notBasketEmpty) {
                  if (validationStatus.validationError) {
                    dispatch(
                      showToast(
                        'Some items were not added to your basket as they are currently unavailable',
                        'warning',
                      ),
                    );
                    this.setState({ continueButtonClicked: false })

                  }
                  forwardTo('/order-summary', { skipBackToThePreviousPage: false });
                } else {
                  dispatch(
                    showToast(
                      'Some items were not added to your basket as they are currently unavailable',
                      'warning',
                    ),
                  );
                  this.setState({ continueButtonClicked: false })

                }
              }
            } else {
              dispatch(showToast('Menu not found', 'warning'));
              this.setState({ continueButtonClicked: false })

            }
          }
          else {
            dispatch(showToast('Menu not found', 'warning'));
            this.setState({ continueButtonClicked: false })
          }
        } else {
          dispatch(
            getIkentooMenusForLocation(businessLocationId, {
              pickTime,
              json_time_selector: choosenRestaurant ? choosenRestaurant.json_time_selector : [],
            }),
          );
          this.setState({ continueButtonClicked: false })

        }
      } else if (!selectedRestaurant) {
        this.setState({ error: 'Please select location' });
      } else {
        this.setState({ error: 'Please select pickup time' });
      }
    })
  };
  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      () => {
        this.setState({
          isLocationAllowed: true,
        });
      },
      (err) => console.log(err),
    );
  };
  isStoreOpened = (selectedRestaurant) => {

    let currentDay = new Date().getDay();
    if (currentDay == 0) {
      currentDay = 7;
    }
    let label = true;

    const openingTimes = selectedRestaurant.json_opening_time_info[currentDay - 1]?.time;

    if (openingTimes && openingTimes.toLowerCase() !== 'closed') {
      const openingTimesForToday = openingTimes.split('-').map((time) => {
        return {
          hour: time.split(':')[0].trim(),
          minutes: time.split(':')[1].trim(),
        };
      });
      const startTime = openingTimesForToday[0];
      const closeTime = openingTimesForToday[1];
      const current = moment().format('HH-mm').split('-');
      const currentTime = { hour: current[0], minutes: current[1] };
      if (currentTime.hour > closeTime.hour || currentTime.hour < startTime.hour) {
        label = false;
      } else if (currentTime.hour < closeTime.hour && currentTime.hour > startTime.hour) {
        label = true;
      } else if (currentTime.hour === closeTime.hour) {
        if (currentTime.minutes == closeTime.minutes) {
          label = true;
        } else if (currentTime.minutes > closeTime.minutes) {
          label = true;
        } else {
          label = false;
        }
      } else if (currentTime.hour === startTime.hour) {
        if (currentTime.minutes == startTime.minutes) {
          label = true;
        } else if (currentTime.minutes > startTime.minutes) {
          label = true;
        } else {
          label = false;
        }
      }
    } else {
      label = false;
    }
    return label;
  };
  formatDataForSelect = (stores) => {
    const { myLocation } = this.props;
    let formatedStores = [];
    stores.forEach((store) => {
      const currentDT = moment();
      let minDT = currentDT;
      if (store && isDefined(store.order_slot_lead_time)) {
        minDT.add(store.order_slot_lead_time, 'minutes');
      }
      const snoozedTimes = checkSnoozedTimes(store, 'collection');

      const storeOpened = this.isStoreOpened(store)
      const timeData = formatDataForTime(store, minDT, store.id, false, false, snoozedTimes);
      const slotsAvailable = timeData.length === 1 && timeData[0].text.toLowerCase() === 'closed' ? false : true


      store.opened = getConfig()?.flags?.hasCollectionASAP ? slotsAvailable && storeOpened : slotsAvailable
      if (getConfig()?.flags?.hasCollectionASAP) {
        if (storeOpened && slotsAvailable) {
          store.openLabel = 'OPEN'

        }
        else{
          store.openLabel = 'CLOSED'

        }
      } else {

        if (storeOpened && slotsAvailable) {
          store.openLabel = 'OPEN'

        }
        else if (storeOpened && !slotsAvailable) {
          store.openLabel = 'UNAVAILABLE'

        }
        else if (!storeOpened && slotsAvailable) {
          store.openLabel = 'PREORDER'

        }
        else if (!storeOpened && !slotsAvailable) {
          store.openLabel = 'CLOSED'

        }
      }

      if (store.is_published && store.can_collection_order) {
        formatedStores.push(store);
      }
    });
    if (this.state.isLocationAllowed || (myLocation?.latitude && myLocation?.longitude)) {
      formatedStores.sort(function (a, b) {
        return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
      });
    } else {
      formatedStores.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    }
    return formatedStores;
  };

  selectStore = (store) => {
    this.setState({ selectedRestaurant: store });
  };

  selectTime = (time) => {
    this.setState({ pickTime: time });
  };
  backHandler = () => {
    if (this.state.showTime) {
      this.setState({ showTime: false });
    } else {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path);
    }
  }
  render() {
    const {
      __,
      restaurants,
      ikentooMenusForLocation,
      isChooseMenuModalOpen,
      deliveryOption,
      orderProductionMins,
    } = this.props;
    const {
      error,
      showRestaurants,
      showTime,
      selectedRestaurant,
      pickTime,
      selectedIkentooMenu,
      selectedTimeOption,
    } = this.state;
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant?.id) || null;
    const stores = restaurants || [];
    const formatedStores = this.formatDataForSelect(stores);
    const mins = isDefined(orderProductionMins) ? orderProductionMins : 15;
    const currentDT = moment();
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';

    // const timeFormat = 'HH:mm'

    // Added period to prevent time in past 12:51 = 12:55 should be possible to select (min time)
    let minDT = currentDT;
    //include 'Order slot lead time' from the BO
    if (store && isDefined(store.order_slot_lead_time)) {
      minDT.add(store.order_slot_lead_time, 'minutes');
    }
    const snoozedTimes = checkSnoozedTimes(store, 'collection');
    const showSnoozedAlert = (e) => {
      if (snoozedTimes.includes(e.valueText)) {
        mobiscroll.alert({
          title: 'Warning',
          message: "Sorry, we're very busy, please select another time slot",
          callback: this.handleModalDismissed,
        });
        return false;
      }
    };
    const timePickerOptions = formatDataForTime(
      selectedRestaurant,
      minDT,
      selectedRestaurant?.id,
      false,
      false,
      snoozedTimes
    );
    const timeOptions = [
      { text: 'ASAP - ready in 15 minutes', value: 1, show: false },
      { text: 'Pre-order for later', value: 2, show: true },
    ];
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.json_time_selector : [],
    );
    /**/
    return (
      <Loading transparent>
        <Layout
          headerWithTitle={true}
          backHandler={this.backHandler}
          scrollY={false}
          hideSecondToolbar={true}
          headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          className=" click-collect-page"
          color="transparent"
          showHamburgerIcon={!this.state.showTime}
        >
          <div className="absolute-content">
            {!showTime && (
              <>
                <div className="click-collect-title">
                  <Title className='web-only'>{__('Click & Collect Order')}</Title>
                  <NormalText>
                    {__('Select a restaurant to collect your order')}
                  </NormalText>
                </div>
                <div className="click-collect-locations">
                  <IonList className='box-wrapper' lines='none'>
                    {formatedStores.map((store, index) => (
                      <div className="click-collect-items" key={index}>
                        <IonItem
                          disabled={!store.opened}
                          onClick={() => this.selectStore(store)}
                          lines="none"
                          className='default-padding '
                        >
                          <IonLabel className='dark-text'>
                            <StrongText >{__(store.name)}</StrongText>
                            <SmallText className="block">{__(store.address)}</SmallText>
                            {store.distance && (
                              <div className="bold-description click-collect-small-description">
                                {store.distance < 1
                                  ? store.distance.toFixed(1)
                                  : store.distance.toFixed(0)}{' '}
                                {__('miles')}
                              </div>
                            )}
                            <NormalText className='uppercase secondary-color'>{__(store.openLabel)}</NormalText>

                          </IonLabel>
                          <IonCheckbox
                            checked={selectedRestaurant && store.id === selectedRestaurant.id}
                            slot="start"
                            color="secondary"
                          />
                        </IonItem>
                      </div>
                    ))}
                  </IonList>
                </div>
                <div className="click-collect-button">
                  <IonButton
                    disabled={!selectedRestaurant}
                    expand="block"
                    color="secondary"
                    onClick={() => this.selectRestaurant(selectedRestaurant)}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </>
            )}
          </div>

          {showTime && (
            <>
              <div className="click-and-collect-title">
                <Title className="web-only">{__('Scheduled Click & Collect')}</Title>
                <NormalText>{__('Select a time to collect your order')}</NormalText>
              </div>
              <Spacer size={1} />
              <div className='box-wrapper click-and-collect-timepicker-wrapper'>
                <SelectOption
                  display="inline"
                  onSet={(e, inst) => this.setPickTime(inst, minDT)}
                  data={timePickerOptions}
                  label="Location"
                  value={pickTime}
                  inputStyle="box"
                  placeholder={__('Select Collection Time')}
                  setText={__('OK')}
                  cancelText={__('Cancel')}
                  disabled={this.state.selectedRestaurant === null ? true : false}
                  onInit={() => {
                    if (timePickerOptions.length > 0) {
                      const firstAvailableTime = timePickerOptions.find(
                        (i) => i.value !== null && !snoozedTimes.includes(i.value),
                      );
                      if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
                        this.changeTime(firstAvailableTime.value, minDT);
                      }
                    }
                  }}
                />
              </div>
              <Spacer size={1} />
              <div className="click-collect-button-wrapper">
                <IonButton
                  disabled={!pickTime}
                  expand="block"
                  color="secondary"
                  className={this.state.continueButtonClicked ? 'unclicked' : ''}
                  onClick={() => this.chooseMenusForLocation()}
                >
                  {__('Continue')}
                </IonButton>
                <Spacer size={1} />

                <NormalText>{__("You have 5 minutes to complete your order to guarantee this collection time.")}</NormalText>
              </div>
            </>

          )}
          <div
            className="click-collect-pickers-backdrop"
            style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
            onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
          ></div>
          <div className={`click-collect-dialog ${animationMenuClass}`}>
            <div className="click-collect-dialog-layout sc-ion-modal-md">
              <div className="click-collect-dialog-header">
                <h3>{__('Choose menu')}</h3>
              </div>
              <div
                className="click-collect-dialog-closer"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
              >
                <ion-icon
                  name="close"
                  role="img"
                  className="md hydrated"
                  aria-label="close"
                ></ion-icon>
              </div>
              <div className="click-collect-dialog-content">
                <IonList lines="none">
                  <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                    {menus.length === 0 ? (
                      <NoData />
                    ) : (
                      menus.map((menu) => {
                        const { ikentooMenuId, menuName } = menu;
                        return (
                          <IonItem key={ikentooMenuId} lines="full">
                            <div tabIndex="-1"></div>
                            <IonLabel className="ion-text-wrap">
                              <Sectiontitle>{menuName}</Sectiontitle>
                            </IonLabel>
                            <IonRadio color="primary" slot="start" value={ikentooMenuId} />
                          </IonItem>
                        );
                      })
                    )}
                  </IonRadioGroup>
                </IonList>
              </div>
              <div className="click-collect-dialog-action">
                {error ? (
                  <IonItem>
                    <div tabIndex="-1"></div>
                    <FieldError className="field-error" value={__(error)} />
                  </IonItem>
                ) : null}
                <IonButton
                  disabled={pickTime && menus.length > 0 ? false : true}
                  expand="block"
                  color="secondary"
                  className={this.state.continueButtonClicked ? 'unclicked' : ''}
                  onClick={() => this.continueOnMenu()}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isChooseMenuModalOpen, orderProductionMins, myLocation, navConfig } = state.common;
  const { restaurants, ikentooMenu, isShowTimePicker, ikentooMenusForLocation } = state.restaurants;
  const { deliveryOption } = state.orders;
  return {
    auth,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    profile: state.profile.profile,
    isShowTimePicker: isShowTimePicker,
    deliveryOption,
    orderProductionMins: orderProductionMins,
    myLocation: myLocation,
    navConfig
  };
};

export default connect(stateToProps)(withTranslation(ClickAndCollect));
