import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
  IonMenuToggle,
} from '@ionic/react';
import { scan, basket, arrowBack, search } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import { SmallText, StrongText, Subtitle, Title } from '../common';
import { forwardTo, getRouteClassName, isWebConfig, goBack } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import menuWhite from '../../assets/images/menu-white.svg';
import menuDark from '../../assets/images/menu-dark.svg';
import './index.css';

const StaticHeader = ({ __, auth, profile, history, backHandler, title, headerWithTitle, showHamburgerIcon, navConfig, hasLoyaltyPoints, onSearchHandler, hasSearchIcon, searchActive, showScanIcon, hideBasket }) => {
  const currentPath = history.location.pathname;
  const config = getConfig();
  const authPages = config.general.authRoutes.indexOf(currentPath) !== -1;
  const pagesWithBackButton = config.general.routesWithBackButton.indexOf(currentPath) !== -1;
  const hideStaticHeader = config.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const routeClassName = getRouteClassName(currentPath, navConfig);
  const routesWithDarkBg = config.general.routesWithDarkBg.indexOf(currentPath) !== -1;
  return (
    <>
      {hideStaticHeader ? null : config.theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar className={`${headerWithTitle ? 'no-logo' : ''} primary-toolbar`}>
            <IonButtons slot="start">
              {showHamburgerIcon ?

                <>
                  {!authPages && !isWebConfig() ?
                    <IonMenuToggle className="menu-toggle-button">
                      <IonIcon color={routesWithDarkBg ? 'white' : 'dark'} icon={routesWithDarkBg ? menuWhite : menuDark}/>
                    </IonMenuToggle>
                    :
                    <div className="header-back-button">
                      <IonIcon onClick={() => backHandler ? backHandler() : goBack()} color={routesWithDarkBg ? 'white' : 'dark'} icon={arrowBack} /> 
                    </div>
                  }
                </>
                :
                <>
                  {!authPages && !pagesWithBackButton ? (
                    !isWebConfig() ? (
                      <IonMenuToggle className="menu-toggle-button">
                        <IonIcon color={routesWithDarkBg ? 'white' : 'dark'} icon={routesWithDarkBg ? menuWhite : menuDark}/>
                      </IonMenuToggle>
                    ) : null
                  ) : (
                    <div className="header-back-button">
                      <IonIcon onClick={() => backHandler ? backHandler() : goBack()} color={routesWithDarkBg ? 'white' : 'dark'} icon={arrowBack} /> 
                    </div>
                  )}</>
              }

            </IonButtons>
            {!headerWithTitle ?
              <IonButtons style={{ top: 0, bottom: 0, left: 0, right: 0, position: 'absolute', width: '100%', zIndex: '-1' }} color="primary">
                <IonButton
                  className="image-button"
                  onClick={() => forwardTo('/dashboard')}
                />
              </IonButtons>
              :
              <IonButton className='header-title' onClick={() => forwardTo('/dashboard')}>
                <Title >{title}</Title>

              </IonButton>
            }
            {hasSearchIcon && searchActive ? (
              <IonButtons slot="end" className='button-active'>
                <IonIcon slot="icon-only" icon={search} onClick={() => onSearchHandler()}/>
              </IonButtons>
              ) : null
            }
            <IonButtons slot="end">
            {hasSearchIcon ? (
								<IonIcon slot="icon-only" icon={search} onClick={() => onSearchHandler()}/>
							) : (
								<>
                  {Basket.itemsCount() > 0 ||  (isWebConfig() && Basket.itemsCount() > 0) ? (
                    <IonButton
                      disabled={Basket.itemsCount() > 0 ? false : true}
                      color="dark"
                      className={`basket-button ${hideBasket ? 'invisible' : ''}`}
                      onClick={() => forwardTo('/order-summary')}
                      size={24}
                    >
                      <div>
                        <div className="basket-icon-wrapper">
                          <IonBadge
                            slot="end"
                            color={routesWithDarkBg ? 'white' : 'dark'}
                            className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                          >
                            {Basket.itemsCountAll()}
                          </IonBadge>
                          <Icon color={routesWithDarkBg ? 'white' : 'dark'} icon={basket} classname="icon" />
                        </div>
                        <div>
                          <SmallText color={routesWithDarkBg ? 'white' : 'dark'}>{Basket._getTotal()}</SmallText>
                        </div>
                      </div>
                    </IonButton>
                  ) : !authPages && !isWebConfig() ? (
                    config.appType.hasLoyalty ? (
                      hasLoyaltyPoints && auth.loggedIn && !showScanIcon ?
                        <div onClick={() => forwardTo('/loyalty')} className='header-points'>
                          <>
                            <Subtitle className='bold' color={routesWithDarkBg ? 'white' : 'dark'}>{profile.available_balance}</Subtitle>
                            <SmallText color={routesWithDarkBg ? 'white' : 'dark'}>{__('pts')}</SmallText>
                          </>
                        </div>
                        :
                          <IonIcon onClick={() => forwardTo('/loyalty', { tab: 'scan' })} color={routesWithDarkBg ? 'white' : 'dark'} slot="icon-only" icon={scan} />
                    ) : null
                  ) : null}
                </>
              )
            }
            </IonButtons>
          </IonToolbar>
          {/* <IonToolbar color="transparent" /> */}
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  const { navConfig } = store.common;
  const { profile, auth } = store.profile
  return {
    basketUpdated: orders.basketUpdated,
    navConfig,
    profile,
    auth
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
