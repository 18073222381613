import React from 'react';
import { Subtitle } from '../common';
import './index.css';

const HistoryOrderBar = ({ label, onDismiss, handleClick }) => (
  <div className='history-order-bar-wrapper promo-bar-wrapper'>
    <div className="history-order-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
    <div className="promo-bar info" onClick={handleClick}>
      <Subtitle className="capitalized history-order-bar-label">{label}</Subtitle>
    </div>
  </div>
);

export default HistoryOrderBar;
