import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { forwardTo, isDefined, isWebConfig, makeKey } from '../../lib/utils';
import { removeVoucher, setModal } from '../../store/actions';
import { NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../common';
import Incrementer from '../incrementer';
import Modal from '../modal';
import './index.css';

class OrderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChargeModalOpen: false,
      showCharge: false,
      serviceChargeAmount: null,
      serviceChargeBoxModalOpen: false,
    };
  }

  handleServiceChargeModal = (flag) => {
    if (isWebConfig()) {
      this.setState({ serviceChargeModalOpen: flag, showCharge: !flag });
    } else {
      forwardTo('/service-charge');
    }
  };

  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;
    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow className="sub-item" key={makeKey(choiceGroupIndex, choiceIndex, sku)} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/>
              <IonCol size="50px"></IonCol>
              <IonCol className="paddLR grow">
                {choice.quantity} x {Basket.getProductName(choice, profile)}
              </IonCol>
              <IonCol className="righted paddLR self-aligned">
                {basketInstance.calculateSubItemPrice(choice, choice.quantity) !== 0 ? basketInstance._calculateSubItemPrice(choice, choice.quantity) : null}
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };
  drawSubItemsText = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;
    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <div className="flex-justify-between flex-col-wrapper">
              <SmallText>+ {Basket.getProductName(choice, profile)}</SmallText>
              <SmallText>{basketInstance.calculateSubItemPrice(choice, choice.quantity) !== 0 ? basketInstance._calculateSubItemPrice(choice, choice.quantity) : null}</SmallText>
            </div>
          );
        });
      });
    }
    return null;
  };
  selectServicePercentage(serviceCharge) {
    const basketInstance = this.props.basketInstance || Basket;
    const serviceChargeApplied = basketInstance.getServicePercentage();
    return serviceCharge === serviceChargeApplied;
  }
  drawServiceChargeBox = (__, service_charge, basketInstance) => {
    return (
      <div className="service-charge-box-wrapper  centered">
        <div>
          {!getConfig().serviceChargeBoxHidden && <NormalText>{__('Add a service charge?')}</NormalText>}
          <NormalText>{basketInstance.getServiceChargeValue() > 0 ? basketInstance.getFormatedServiceChargeValue() : basketInstance.getServiceCharge(true)}</NormalText>
        </div>
        <div className="service-charge-values">
          {service_charge.map((item) => {
            return (
              <IonButton
                className={this.selectServicePercentage(item) && 'is-active'}
                key={item + 'service_charge'}
                expand="block"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  document.querySelectorAll('.service-charge-values ion-button').forEach((el) => el.classList.remove('is-active'));
                  e.target.classList.add('is-active');
                  basketInstance.setServicePercentage(item);
                }}
              >
                {item === 0 ? <strong>{__('No thanks')} </strong> : <strong>{item}% </strong>}
              </IonButton>
            );
          })}
        </div>

        <IonButton onClick={() => this.setState({ serviceChargeModalOpen: true, serviceChargeBoxModalOpen: false })} fill="clear" className="link underlined">
          {__('Add different amount')}
        </IonButton>
        <IonButton
          color="primary "
          onClick={() => {
            basketInstance.setServicePercentage(0);
            this.setState({ serviceChargeBoxModalOpen: false });
          }}
          fill="clear"
          className="link underlined block decline-service-charge-btn"
        >
          {__('Not this time')}
        </IonButton>
      </div>
    );
  };
  setCustomServiceCharge = (value) => {
    if (value >= 0) {
      this.setState({ serviceChargeAmount: value, customAmountErrorVisible: false });
    } else {
      this.setState({ customAmountErrorVisible: true, serviceChargeAmount: 0 });
    }
  };
  render() {
    const { handleOrderItemClick, __, history, profile, showAddItems, dispatch, isRemoveVoucherModalOpen } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const orderType = basketInstance.getOrderType();
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const { serviceChargeModalOpen } = this.state;
    const appliedVoucher = basketInstance.getAppliedVoucher();

    let location = history && history.location && history.location.pathname === '/history-details' ? true : false;
    let service_charge_original;
    let service_charge = [];
    if (!basketInstance.service_charge_value && basketInstance.service_charge_value !== 0 && orderType === 'Table') {
      if (basketInstance.getRestaurantServiceCharge()) {
        service_charge_original = basketInstance.getRestaurantServiceCharge();
      } else {
        service_charge_original = JSON.parse(getConfig().general.defaultServiceCharge);
      }
      service_charge = service_charge_original.map((el) => parseInt(el * 100));
    }

    return (
      <>
        {getConfig().general.hasServiceCharge && !basketInstance.service_charge_value && basketInstance.service_charge_value !== 0 && orderType === 'Table' && (
          <>
            {!getConfig().general.serviceChargeBoxHidden ? (
              <>{this.drawServiceChargeBox(__, service_charge, basketInstance)}</>
            ) : (
              <>
                <Modal className="service-charge-box-modal" isOpen={this.state.serviceChargeBoxModalOpen} onDidDismiss={() => this.setState({ serviceChargeBoxModalOpen: false })}>
                  <Title>{__('Add Service Charge')}</Title>
                  <Spacer size={1} />
                  {this.drawServiceChargeBox(__, service_charge, basketInstance)}
                  <Spacer size={1} />
                  <IonButton
                    expand="block"
                    color="primary"
                    onClick={() => {
                      this.setState({ serviceChargeBoxModalOpen: false });
                    }}
                  >
                    {__('Add Service Charge')}
                  </IonButton>
                </Modal>
              </>
            )}
            <Spacer size={1} />
          </>
        )}
        <Subtitle className=" order-summary-items-wrapper">{__('Your order')}</Subtitle>
        <div className="basket-items-wrapper">
          <div className="add-items-button-wrapper">
            {showAddItems ? (
              <IonButton fill="clear" size="small" color="secondary" className="add-items-btn link underlined" onClick={() => forwardTo('/order')}>
                {__('Add Items')}
              </IonButton>
            ) : null}
          </div>
          {basketInstance.getItems().map((basketItem, basketItemIndex) => {
            const { quantity, item, instructions } = basketItem;
            let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
            if (image && image.indexOf('http://') !== -1) {
              image = image.replace(/http:\/\//g, 'https://');
            }
            return (
              <div className="box-content">
                <div
                  className="order-item-image"
                  style={image && image !== '' ? { backgroundImage: `url(${image})` } : {} }
                />
                <div className="item-content-wrapper flex-justify-between flex-row-wrapper">
                  <div>
                    <div className="flex-justify-between flex-col-wrapper">
                      <NormalText className="block bold">{Basket.getProductName(item, profile)}</NormalText> 
                      <NormalText className="block bold">{item && item.productPrice ? Basket.formatPrice(item.productPrice): ''}</NormalText>
                    </div>
                    {this.drawSubItemsText(basketItem, basketItemIndex)}
                    {basketItem.instructions !== '' && (
                      <>
                        <Spacer size={1} />
                        <SmallText className="block">{`${__('Instructions')}: ${basketItem.instructions}`} </SmallText>
                      </>
                    )}
                  </div>
                  <div className="item-content-actions flex-justify-between flex-col-wrapper flex-align-end">
                    <Incrementer
                       onUpdate={(val) => {
                        if (handleOrderItemClick) {
                          handleOrderItemClick(basketItem, basketItemIndex, val);
                        }
                      }}
                      quantity={quantity}
                      step={1}
                      allowNegative={false}
                      enableLastItemAlert={true}
                    >
                      <StrongText className="bold">{quantity}</StrongText>
                    </Incrementer>
                    <NormalText>
                      {basketInstance.calculateItemPriceByIndex(basketItemIndex, true) > 0 ? basketInstance._calculateItemPriceByIndex(basketItemIndex, true) : null}
                    </NormalText>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <IonGrid className="box-content order-content-wrapper paddL">
          <>
            {((basketInstance.service_percentage > 0 || basketInstance.service_percentage === false) && orderType === 'Table' && getConfig().general.hasServiceCharge) ||
              basketInstance.getServiceChargeValue() > 0 ? (
              <div key="service_charge" className="basket-item-wrapper">
                <IonRow className="basket-item-service-charge-row service-charge-item" onClick={() => this.setState({ serviceChargeBoxModalOpen: true })}>
                  <>
                    <IonCol className="paddLR grow ">
                      {__('Service charge')}&nbsp;
                      {basketInstance.service_charge_value ? (
                        <> {basketInstance.service_percentage !== 0 && basketInstance._getServicePercentage()}</>
                      ) : (
                        <> {basketInstance.service_percentage !== false && basketInstance._getServicePercentage()}</>
                      )}
                    </IonCol>
                    <IonCol className="righted paddLR self-center ">
                      {basketInstance.getServiceChargeValue() > 0 ? basketInstance.getFormatedServiceChargeValue() : basketInstance.getServiceCharge(true)}
                    </IonCol>
                  </>
                </IonRow>
              </div>
            ) : null}
            {deliveryPriceUnformated > 0 ? (
              <IonRow>
                <IonCol className="paddLR grow">
                  {basketInstance.getRestaurant()?.delivery_charge_in_percent && basketInstance.geRestaurantDeliveryPrice()
                    ? `${basketInstance.geRestaurantDeliveryPrice()}% `
                    : null}
                  {__('Delivery Charge')}
                </IonCol>
                <IonCol className="righted paddLR">
                  {/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
                </IonCol>
              </IonRow>
            ) : null}
            {basketInstance.process_fee_value > 0 && (
              <IonRow className="">
                <>
                  <IonCol className="paddLR grow ">
                    {Basket.processing_fee_description > 0 ? `${Basket.processing_fee_description}% ` : null}
                    {__('Order processing fee')}
                  </IonCol>
                  <IonCol className="righted paddLR self-center ">{basketInstance.getProcessingFee()}</IonCol>
                </>
              </IonRow>
            )}
            {basketInstance.discount && (
              <IonRow>
                <IonCol className="paddLR grow">{basketInstance.discount.title}</IonCol>
                <IonCol className="righted paddLR">-{basketInstance.formatPrice(basketInstance.calculateMembershipDiscount())}</IonCol>
              </IonRow>
            )}
          </>
          <Modal className="service-charge-modal" isOpen={serviceChargeModalOpen} onDidDismiss={() => this.handleServiceChargeModal(false)}>
            <Title>{__('Add service charge')}</Title>
            <Spacer size={1} />
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput className="okx-font-secondary" onIonChange={(e) => this.setCustomServiceCharge(e.target.value)} type="number" pattern="number" inputmode="number"></IonInput>
            </IonItem>
            <Spacer size={1} />
            {this.state.customAmountErrorVisible && (
              <>
                <SmallText className="danger-color">{__('Value can not be negative')}</SmallText>
                <Spacer size={1} />
              </>
            )}
            <IonButton
              expand="block"
              onClick={() => {
                basketInstance.setServiceCharge(this.state.serviceChargeAmount);
                this.setState({ serviceChargeModalOpen: false });
              }}
              disabled={!this.state.serviceChargeAmount}
            >
              {this.state.serviceChargeAmount ? (
                <>
                  {__('Add')} {basketInstance.formatPrice(this.state.serviceChargeAmount)}
                </>
              ) : (
                <>{__('Add')}</>
              )}
            </IonButton>
          </Modal>
        </IonGrid>
        {(isDefined(appliedVoucher) && appliedVoucher.length) || (isDefined(appliedPoints) && appliedPoints > 0) || basketInstance.discount ? (
          <>
            <Spacer size={1} />
            <IonGrid class="box-wrapper order-content-wrapper">
              <IonRow>
                <IonCol>
                  <IonCol>{__('Items total')}</IonCol>
                </IonCol>
                <IonCol className="righted ">{basketInstance._getSubTotal(true)}</IonCol>
              </IonRow>
              {isDefined(appliedPoints) && appliedPoints > 0 ? (
                <IonRow
                  className="pointer"
                  onClick={() => {
                    if (type === 'order' && history) {
                      history.push('/apply-points');
                    }
                  }}
                >
                  <IonCol className="paddLR grow">
                    {appliedPoints}&nbsp;{__('points redeemed')}
                  </IonCol>
                  <IonCol className="righted paddLR">{basketInstance._calculatePointsAppliedPrice(null, true)}</IonCol>
                </IonRow>
              ) : null}
              {appliedVoucher &&
                appliedVoucher.length > 0 &&
                appliedVoucher.map((voucher) => {
                  let voucherName = voucher.name;
                  if (voucher && voucher.reward && voucher.reward.name) {
                    voucherName = voucher.reward.name;
                  }
                  return (
                    <>
                      <IonRow key={voucher.id} onClick={() => (location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true)))}>
                        <IonCol className="paddLR grow ">{__(voucherName)} </IonCol>
                        <IonCol className="righted paddLR">{basketInstance._calculateAppliedVocuhersPrice(true, null)}</IonCol>
                      </IonRow>
                      <Modal
                        className="remove-apply-voucher"
                        actionLabel={__('Yes')}
                        action={() => dispatch(removeVoucher(voucher.id))}
                        isOpen={!!isRemoveVoucherModalOpen}
                        onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                      >
                        <Subtitle>{__('Do you want to remove voucher') + ' ?'}</Subtitle>
                      </Modal>
                    </>
                  );
                })}

              <IonRow>
                <IonCol>
                  <IonCol>{__('Order total')}</IonCol>
                </IonCol>
                <IonCol className="righted">{basketInstance._getTotal(true)}</IonCol>
              </IonRow>
            </IonGrid>
          </>
        ) : null}
        <Spacer size={1} />
        <IonGrid class="box-wrapper order-content-wrapper">
          <IonRow>
            <IonCol>
              <IonCol className="bold">{__('Total to pay')}</IonCol>
            </IonCol>
            <IonCol className="righted  bold">{basketInstance._getTotal(true)}</IonCol>
          </IonRow>
        </IonGrid>
        {basketInstance.service_percentage === 0 &&
          orderType === 'Table' &&
          getConfig().general.hasServiceCharge &&
          getConfig().general.serviceChargeBoxHidden &&
          basketInstance.getServiceChargeValue() === null ? (
          <div key="service_charge" className="basket-item-wrapper">
            <IonButton
              expand="block"
              fill="clear"
              color="primary"
              className="link underlined basket-item-service-charge-row"
              onClick={() => this.setState({ serviceChargeBoxModalOpen: true })}
            >
              {__('add service charge')}
            </IonButton>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
    clientProfile: store.common.clientProfile,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
