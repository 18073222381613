import { Capacitor } from '@capacitor/core';
import { CameraResultType, CameraSource } from '@capacitor/camera';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonLabel, IonInput, IonList, IonCheckbox, IonAlert, IonIcon } from '@ionic/react';
import S3 from 'aws-s3';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import { NormalText, Spacer, FieldError, Title, Subtitle } from '../../components/common';
import { validateForm, isDefined, isWebConfig, forwardTo, getDefaultRoute } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { updateProfile, setModal, loading, deleteUser, logout } from '../../store/actions';
import { beforeCloseTimePicker } from '../../store/actions';
import ValidateButton from '../../components/validateButton';
import Loading from '../../components/spinner';
import './index.css';
import defaultImg from '../../assets/images/gray-avatar.png';
import Mobiscroll from '../../components/mobiscroll';
import logOutIcon from '../../assets/images/logout-icon.svg'
import { Camera } from '@capacitor/camera/dist/esm';

const { configS3 } = getConfig();
const S3Client = new S3(configS3);
const { DatePicker, SelectOption } = Mobiscroll;

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      email: this.props.profile.email || '',
      mobile: this.props.profile.mobile || '',
      birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
      imageFile: null,
      is_subscribed: this.props.profile.is_subscribed || false,
      formErrors: {},
      deleteAvatarImageAlert: false,
      locale: this.props.profile.locale || getConfig().localization.defaultLocale,
      closeMyAccount: false,
      saveAccountIsOpen: false,
      mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
      mobile_value: this.props.profile.mobile_value
        ? this.props.profile.mobile_value
        : this.props.profile.mobile == this.props.profile.mobile_code
        ? ''
        : this.props.profile.mobile,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formConfig = {
      email: { type: 'email', required: false },
      mobile: { type: 'tel', required: false },
      locale: { type: 'text', required: false },
    };
    this.triggerInputFile = this.triggerInputFile.bind(this);
    this.inputRef = React.createRef();
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.email !== prevProps.profile.email) {
      this.setState({ email: this.props.profile.email });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({
        mobile: this.props.profile.mobile,
        mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
        mobile_value: this.props.profile.mobile_value
          ? this.props.profile.mobile_value
          : this.props.profile.mobile == this.props.profile.mobile_code
          ? ''
          : this.props.profile.mobile,
      });
    }
    if (this.props.profile.locale !== prevProps.profile.locale) {
      this.setState({
        locale: this.props.profile.locale || getConfig().localization.defaultLocale,
      });
    }
    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({ profile_image_url: this.props.profile.profile_image_url });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
    if (this.props.profile.is_subscribed !== prevProps.profile.is_subscribed) {
      this.setState({ is_subscribed: this.props.profile.is_subscribed });
    }
  }

  handleInput = (key, val) => {
    if (key == 'mobile_value') {
      this.setState({ mobile_value: val, mobile: `${this.state.mobile_code}${val}` });
    } else {
      this.setState({ [key]: val });
    }
    this.props.dispatch(beforeCloseTimePicker());
  };

  handleLanguageInput = (event, data) => {
    this.setState({ locale: data.getVal() });
  };

  async takePicture() {
    if (Capacitor.getPlatform() !== 'web') {
      await Camera .getPhoto({
        quality: 50,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
      }).then((imageData) => {
        this.setState({
          imageFile: imageData.dataUrl,
          extension: imageData.format,
          profile_image_url: imageData.dataUrl,
        });
      });
    }
  }

  getMimeType = (extension) => {
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      default:
        return undefined;
    }
  };

  handleSave(fromAlert = false) {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { 
        first_name, 
        last_name, 
        email, 
        mobile, 
        birthday, 
        profile_image_url, 
        imageFile, 
        is_subscribed, 
        locale,
        mobile_code,
        mobile_value,
       } = this.state;
      const profile = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        birthday: birthday,
        profile_image_url: !this.props.profile.profile_image_url ? null : profile_image_url,
        is_subscribed,
        locale,
        mobile_code,
        mobile_value,
      };
      if ((!this.props.profile.birthday || this.props.profile.birthday == '') && !fromAlert && birthday !== '') {
        this.setState({ saveAccountIsOpen: true });
        return;
      }
      if (!isDefined(imageFile)) {
        this.props.dispatch(updateProfile(profile));
      } else {
        const imageName = configS3.imageNamePrefix + this.props.profile.id; //+ '.' + this.state.extension
        this.props.dispatch(loading(true));
        fetch(this.state.profile_image_url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new window.File([blob], imageName, {
              type: this.getMimeType(this.state.extension),
            });
            S3Client.uploadFile(file, imageName)
              .then((data) => {
                profile.profile_image_url = data.location;
                this.props.dispatch(updateProfile(profile));
                this.setState({ imageFile: null });
                this.props.dispatch(loading(false));
              })
              .catch(() => {
                this.props.dispatch(loading(false));
              });
          });
      }
    }
  }

  removeProfileImage = () => {
    const { profile } = this.props;
    const profile_image_url = profile.profile_image_url;
    this.handleAvatarDeleteAlert(false);
    if (profile_image_url) {
      let imageName = profile_image_url.split('/');
      if (imageName.length > 0) {
        imageName = imageName[imageName.length - 1];
        this.props.dispatch(updateProfile({ profile_image_url: null }));
        this.setState({ imageFile: null });
      }
    }
  };

  triggerInputFile() {
    if (Capacitor.getPlatform() === 'web') {
      if (this.inputRef) {
        this.inputRef.current.click();
      }
    } else {
      this.takePicture();
    }
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    const imageFile = event.target.files[0];
    let reader = new window.FileReader();
    reader.readAsDataURL(imageFile);
    let extension = imageFile.name.split('.').pop();
    reader.onloadend = () => {
      this.setState({
        imageFile,
        profile_image_url: reader.result,
        extension,
      });
    };
  }

  handleAvatarDeleteAlert = (flag = true) => this.setState({ deleteAvatarImageAlert: flag });

  handlePeriodicalSaga = (flag) => {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_COMMON_PROP', key: 'emitterFlag', value: flag });
  };

  formatDataForSelect = (langs) => {
    const { __ } = this.props;
    let arrForSelect = [];
    Object.keys(langs).forEach((key) => {
      arrForSelect.push({ text: __(langs[key]), value: key });
    });
    return [{ text: '', value: null }, ...arrForSelect];
  };
  handleLogout() {
    this.props.dispatch(logout());
    const defaultRoute = getDefaultRoute(this.props.navConfig);
    forwardTo(defaultRoute.path);
  }
  setMobileCode = (val) => {
    this.setState({ mobile_code: val, mobile: `${val}${this.state.mobile_value}` });
  };

  render() {
    const { __, isProfileModalOpen, history, profile } = this.props;
    const { first_name, last_name, email, mobile, birthday, formErrors, is_subscribed, deleteAvatarImageAlert, locale, closeMyAccount, saveAccountIsOpen, mobile_code, mobile_value } = this.state;
    const languages = Object.keys(getConfig().localization.supportedLocales);
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const defaultDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    const countryCodeData = getConfig().country_code_data || [
      {
        "text": "GB +44",
        "value": "+44"
      }, {
        "text": "US +1",
        "value": "+1"
      }
    ];
    return (
      <Loading>
        <Layout color="transparent" headerTitle="My Account" hideBack={true} hideSecondToolbar={true} scrollY={isWebConfig() ? false : true}>
          <div className="absolute-content scrollable-y">
            <div className="title-wrapper">
              <Title className="primary-color">{__('Your account')}</Title>

              <div className="logout-btn" onClick={() => this.handleLogout()}>
                <NormalText className="block ">{__('Logout')} </NormalText>
                <IonIcon icon={logOutIcon ? logOutIcon : null} />
              </div>
            </div>
            <ValidateButton />
            <Spacer size={1} />
            <IonList>
              <div className="input-field-container">
                <NormalText>{__('First name')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    className="okx-font-secondary"
                    onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                    clearInput
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                  ></IonInput>
                </IonItem>
              </div>
              <div className="input-field-container">
                <NormalText>{__('Last name')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    className="okx-font-secondary"
                    onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                    clearInput
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                  ></IonInput>
                </IonItem>
              </div>
              <div className="input-field-container ">
                <NormalText>{__('Email address')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper disabled-wrapper">
                  <IonInput
                    className="okx-font-secondary"
                    onIonChange={(e) => this.handleInput('email', e.target.value)}
                    required={true}
                    type="email"
                    pattern="email"
                    inputmode="email"
                    value={email}
                  ></IonInput>
                </IonItem>
              </div>
              {formErrors.email ? <FieldError className="field-error" value={__(formErrors.email)} /> : null}
              <div className="input-field-container">
                <NormalText>{__('Mobile Number')}</NormalText>
                <div>
                  <IonItem lines="none" className="input-field-wrapper inline-input-field-wrapper">
                    <div>
                      <SelectOption
                        onSet={(e, val) => this.setMobileCode(val.getVal())}
                        display="center"
                        value={mobile_code}
                        data={countryCodeData}
                        onInit={(e, val) => {
                          if (!mobile_code) {
                            this.setMobileCode(val._tempWheelArray[0]);
                          }
                        }}
                      />
                    </div>
                    <IonInput
                      onIonChange={(e) => this.handleInput('mobile_value', e.target.value)}
                      clearInput
                      required={false}
                      type="tel"
                      pattern="tel"
                      inputmode="tel"
                      placeholder="XXXXXXXXXXXX"
                      value={mobile_value}
                    ></IonInput>
                  </IonItem>
                </div>
              </div>
              <div className='input-field-container'>
                <NormalText>{__('Date Of Birth')}</NormalText>
                <IonItem lines='none' className={`input-field-wrapper ${profile.birthday !== null ? 'disabled-wrapper' : ''}`}>
                  <DatePicker
                    className="data-picker-input"
                    display="bottom"
                    max={yesterday}
                    setText={__('Done')}
                    cancelText={__('Cancel')}
                    lang={profile.locale}
                    value={birthday}
                    onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                    dateFormat="dd/mm/yy"
                    defaultValue={defaultDate}
                    /* onBeforeShow={() => {
                      this.props.dispatch(beforeShowTimePicker());
                    }}
                    onClose={() => {
                      this.props.dispatch(beforeCloseTimePicker());
                    }} */
                  />
                </IonItem>
              </div>
              {languages.length <= 1 ? null : (
								<div className="input-field-container">
									<NormalText>{__('Language')}</NormalText>
									<IonItem lines="none" className="input-field-wrapper dropdown-field">
										<SelectOption
											cssClass="language-picker"
											display="center"
											onSet={(e, a) => this.handleLanguageInput(e, a)}
											data={this.formatDataForSelect(getConfig().localization.supportedLocales)}
											label="Location"
											value={locale}
											inputStyle="box"
											placeholder={__('Select one')}
											cancelText={__('Cancel')}
											setText={__('OK')}
											disabled={this.props.isShowTimePicker ? true : false}
											/* onClose={() => {
												this.handlePeriodicalSaga(true);
											}}
											onShow={() => this.handlePeriodicalSaga(false)} */
										/>
									</IonItem>
								</div>
							)}
              <Spacer size={1} />
              <Subtitle className="uppercase">{__('Terms & conditions')}</Subtitle>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <IonCheckbox color="secondary" slot="start" checked={true} className="unclicked" />
                <IonLabel className="ion-text-wrap">
                  <NormalText>
                    {__('By signing up you agree to our')}{' '}
                    <span className="link underlined" onClick={() => forwardTo('/terms')}>
                      {__('terms and conditions of service')}
                    </span>{' '}
                    {__('and')}{' '}
                    <span className="link underlined" onClick={() => forwardTo('/privacy')}>
                      {__('privacy policy')}
                    </span>
                  </NormalText>
                </IonLabel>
              </IonItem>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <IonCheckbox color="secondary" slot="start" checked={is_subscribed} onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)} />
                <IonLabel className="ion-text-wrap">
                  <NormalText>{__('Opt-in to receive updates, offers and promotions via email')}</NormalText>
                </IonLabel>
              </IonItem>
            </IonList>
            <Spacer />
            <IonButton expand="block" color="secondary" className="uppercase okx-font-secondary" onClick={() => this.handleSave()}>
              {__('Save changes')}
            </IonButton>
            {(getConfig().appType.hasOrdering || getConfig().flags.hasGiftVouchers || getConfig().flags.hasMembership) && (
              <IonButton color="secondary" expand="block" fill="outline" className="uppercase okx-font-secondary" onClick={() => history.push('/cards', { addCardFromAccount: true })}>
                {__('Manage Payment Cards')}
              </IonButton>
            )}
            {(getConfig().flags.hasDeleteAccount ) && (
              <IonButton expand="block" fill="clear" color="danger" className="underlined link" onClick={() => this.setState({ closeMyAccount: true })}>
                {__('Close my account')}
              </IonButton>
            )}
          </div>
        </Layout>
        <IonAlert
          isOpen={isProfileModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isProfileModalOpen', false))}
          header={__('Success')}
          message={__('Profile is updated.')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal('isProfileModalOpen', false)),
            },
          ]}
        />
        <IonAlert
          isOpen={deleteAvatarImageAlert}
          onDidDismiss={() => this.handleAvatarDeleteAlert(false)}
          header={__('Remove')}
          message={__('Do you want to remove profile image.')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.handleAvatarDeleteAlert(false),
            },
            {
              text: __('Remove'),
              handler: () => this.removeProfileImage(),
            },
          ]}
        />
        <IonAlert
          isOpen={closeMyAccount}
          onDidDismiss={() => this.setState({ closeMyAccount: false })}
          header={__('Close Your Account')}
          message={__('Are you sure you wish to close your account? Any points and vouchers you have earned will be permanently lost')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ closeMyAccount: false }),
            },
            {
              text: __('OK'),
              handler: () => this.props.dispatch(deleteUser(profile.user_token)),
            },
          ]}
        />
        <IonAlert
          isOpen={saveAccountIsOpen}
          onDidDismiss={() => this.setState({ saveAccountIsOpen: false })}
          header={__('Save Your Account')}
          message={__("Please double check your date of birth. You will not be able to change this once it's set")}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ saveAccountIsOpen: false }),
            },
            {
              text: __('OK'),
              handler: () => this.handleSave(true),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile, isProfileModalOpen } = state.profile;
  const { isShowTimePicker } = state.restaurants;
  const { navConfig } = state.common;
  return {
    auth,
    profile,
    isProfileModalOpen,
    isShowTimePicker,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Account));
