import { getEnv } from './lib/env.js';

let appConfig = {
	theme: {
		nav: {
			label: 'Black Rooster'
		},
		showHeaderOnAuthRoutes: true,
		showInputIconsOnLogin: false,
		routesWithoutStaticHeader: ['/item-details']
	},
	services: {
		google_maps: {
			google_api_key: getEnv('GOOGLE_API_KEY')
		}
		// stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
	},
	api: {
		// baseURL: "https://curry-leaf-api.5loyalty.com",
		baseURL: getEnv('BASE_URL'),
		// baseURL: 'https://hybrid-master.herokuapp.com',
		// baseURL: 'http://192.168.0.26:8000', //preda
		wordPressBaseURL: 'https://hybridapp.co.uk',
		nativeWallet: getEnv('NATIVE_WALLET_API'),
	},
	general: {
		giftVouchers: [10, 20],
		dashboardText: 'Take a taste adventure with our loyalty app and unlock a world of exclusive privileges with the club',
		clientName: 'Black Rooster',
		appExitRoutes: ['/', '/home', '/dashboard'],
		authRoutes: ['/login', '/register', '/reset-password'],
		routesWithDarkBg: [

			'/dashboard', 
			'/refer-a-friend', 
			'/click-and-collect', 
			'/account', 
			'/card-add', 
			'/feedback', 
			'/social', 
			'/locations', 
			'/loyalty', 
			'/faq', 
			'/history',
			'/gift-vouchers',
			'/order',
			'/order-summary',
			'/apply-loyalty',
			'/checkout',
			'/register',
			'/cards',
			'/order-to-table',
			'/delivery-address-check',
			'/delivery-options',
			'/login',
			'/order-completed',
			'/reset-password',
			'/history-details',
			'/delivery-address-add',
			'/delivery',
			'/delivery-time',
			'/apply-points',
			"/terms",
			"/privacy",
			"/contact-details",
		],
		routesWithBackButton: [
			'/cards',
			'/card-add',
			'/allergen-information',
			'/order-summary',
			'/order-completed',
			'/apply-vouchers',
			'/terms',
			'/privacy',
			'/contact-details',
			'/click-and-collect',
			'/order-to-table',
			'/history-details',
			'/delivery',
			'/my-membership',
			'/membership-terms',
			'/allergens',
			'/apply-loyalty',
			'/order',
			'/create-account',
			'/checkout',
			'/apply-points'
		],

		isReduxDevToolsOn: true,
		isWebPlatform: false

	},
	// delivery: [
	// 	{
	// 		id: 'collection',
	// 		label: 'Click & Collect',
	// 		route: '/click-and-collect'
	// 	},
	// 	{
	// 		id: 'table',
	// 		label: 'Order To Table',
	// 		route: '/order-to-table'
	// 	},
	// ],
	flags: {
		
	},
	appType: {
		hasOrdering: true,
		hasLoyalty: true,
		hasEmailValidationEnabled: true,
		hasCampaignManager: true,
		referAFriend: true,
		hasCancelOrder: false,
		hasUnlockRewardsOnLoyalty: false,
		isReward: false,
		oldLayoutConfirmationScreens: false,
		hasCollectedPay: false
	},
	configS3: {
		imageNamePrefix: 'hyfive_profile_image_',
		bucketName: 'hybrid-apps-profile-images',
		region: 'eu-west-1',
		accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
		secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY')
	},

	firebaseConfig: {
		apiKey: getEnv('FIREBASE_API_KEY'),
		authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
		databaseURL: getEnv('FIREBASE_DATABASE_URL'),
		projectId: getEnv('FIREBASE_PROJECT_ID'),
		storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
		messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
		appId: getEnv('FIREBASE_APP_ID'),
	},
	payment: 'stripe',
	socialLogin: {
		googleClientId: getEnv('GOOGLE_CLIENT_ID') || '',
		appleClientId: getEnv('APPLE_CLIENT_ID') || '',
	},
	menuDisplayType: 2,

}

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;
