import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonSlides,
  IonSlide,
} from '@ionic/react';
import Layout from '../../components/layout';
import PromoBar from '../../components/promoBar';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, forwardTo, getSingleDeliveryOption } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { NormalText, Subtitle, Title } from '../../components/common';
import HistoryOrderBar from '../../components/historyOrderBar';
import { getNews } from '../../store/actions';
import Modal from '../../components/modal';
import ticket from '../../assets/images/icons/ticket.svg';
import Slider, { Slide } from '../../components/slider';

const { hasOrdering, hasLoyalty, hasCampaignManager } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelivery: getSingleDeliveryOption(),
      isFullScreenBannerOpened: false
    };
  }
  getElementPadding = () => {
    const padding = 0;
  }
  componentDidMount() {
    this.props.dispatch(getNews());
  }
  openSliderFullImage = async (data) => {
    this.setState({
      selectedBanner: data,
      isFullScreenBannerOpened: true
    })
  }


  render() {
    const { __, screenName, vouchers, orderHistory, news } = this.props;
    const { singleDelivery } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
      return false;
    });
    const dashboardContent = getConfig().dashboardConfig;
    return (
      <Layout hasLoyaltyPoints={true} hideSecondToolbar={true} color="transparent" noPadding={true} showScanIcon={true} scrollY={true}>
        <div>


          {news.length > 0 &&
            <>
              {news.length > 1 ? <>
                <Slider
                  settings={{
                    navigation: false,
                    pagination: false,
                    autoplay: {
                      delay: 3000,
                      disableOnInteraction: false,
                    },
                  }}
                >
                  {news.map((item, i) => (
                    <Slide onClick={() => { item.fullscreen_image && this.openSliderFullImage(item) }} key={`slide-${i}`}>
                      <img src={item.banner_image} />
                    </Slide>
                  ))}
                </Slider>
              </> : <div onClick={() => { news[0].fullscreen_image && this.openSliderFullImage(news[0]) }}>
                <img style={{ maxHeight: '200px', width: '100%' }} src={news[0].banner_image} />
              </div>}

            </>
          }
        </div>
        <div className={`dashboard-promo-bars-wrapper ${orderHistory.length > 0 ? 'padded-top' : ''}`}>
          {hasCampaignManager ? <PromoBar icon={ticket} vouchers={voucherRes} /> : null}
          {orderHistory.filter(el => !el.is_gift && el.status.toLowerCase() !== 'new' && el.status.toLowerCase() !== 'created').length > 0 && (
            <HistoryOrderBar
              label={__('Order again')}
              handleClick={() => forwardTo('./history', { tab: 'order' })}
            />
          )}
        </div>
        <div className='dashboard-content'>
          <div className='dashboard-header' style={orderHistory.length == 0 ? null : { paddingTop: '10px' }}>
            <Title>{__('Welcome')}{' '}{screenName}</Title>
            <NormalText>{__(dashboardContent?.description)}</NormalText>
          </div>
          <div className='dashboard-cards-wrapper'>
            {dashboardContent?.data.map((item, i) => {
              return (
                <div key={i} style={{ background: `${getConfig().dashboardConfig.dashboardCardOverlay ? `${getConfig().dashboardConfig.dashboardCardOverlay},` : ''} url(${item.image})` }} className="dashboard-card " onClick={() => forwardTo(item.path)}>
                  <Subtitle key={i}>{__(item.title)}</Subtitle>
                </div>
              );
            })}
          </div>
        </div>
        <Modal className="banner-fullscreen-image-modal"
          isOpen={this.state.isFullScreenBannerOpened}
        >
          <img onClick={() => { this.setState({ isFullScreenBannerOpened: false }) }} src={this.state?.selectedBanner?.fullscreen_image} />

        </Modal>

      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers } = state.profile;
  const { orderHistory } = state.orders;
  const { news } = state.common
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name}`;
  }
  return {
    news,
    screenName,
    vouchers: vouchers || [],
    orderHistory
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
