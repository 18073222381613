import React, { Component } from 'react';
import {
  IonSegment,
  IonContent,
  IonSegmentButton,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import './index.css';
import { isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import redeemIcon from '../../assets/images/reedem-point-icon.svg';
import { StrongText, Subtitle } from '../common';
import Slider, { Slide } from '../slider';

class SwipableTabs extends Component {
  constructor(props) {
    super(props);
    const { defaultTab } = this.props;
    this.state = {
      selectedTab: defaultTab || 0,
    };
  }

  componentDidMount() {

    if (
      this.props.history &&
      this.props.history.state &&
      this.props.history.state.tab === 'order'
    ) {
      this.segmentChanged(1);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.history && prevProps.history.state !== this.props.history.state) {
      // fix: redirect to the scan tab if user is located in 'loyalty' page but on the first tab
      // and that he clicks on scan icon in header
      if (this.props.history.state?.tab === 'scan') {
        this.segmentChanged(1);
      } else if (
        this.props.history &&
        this.props.history.state &&
        this.props.history.state.tab === 'points'
      ) {
        this.segmentChanged(0);
      }
    }
  }

  segmentChanged = (value) => {
    this.setState({ selectedTab: value });
    if (this.state.swiper) {
      this.state.swiper.slideTo(value);
    }
  };

  // On Segment change slide to the matching slide
  onSegmentChange = (ev) => {
    this.slideTo(ev.detail.value);
  };

  slideTo = (index) => {
    this.slides.slideTo(index);
  };

  // On Slide change update segment to the matching value
  onSlideDidChange = async () => {
    const index = await this.slides.getActiveIndex();
    this.clickSegment(index);
  };

  clickSegment = (index) => {
    this.segment.value = index;
  };

  render() {
    const { selectedTab } = this.state;
    const { available_balance, __, displayFooter, displayFooterTab } = this.props;
    const tabs = this.props.tabs || [];

    return (
      <div className="tabs">
        <div className="segment-holder">
          <IonSegment
            onIonChange={(e) => this.segmentChanged(e.detail.value)}
            value={selectedTab}
            mode="md"
          >
            {tabs.map((tab, index) => {
              const { label, icon } = tab;
              return (
                <IonSegmentButton key={index + '_segment'} value={index}>
                  {isDefined(icon) ? <IonIcon name={icon}></IonIcon> : null}
                  <IonLabel>{label || ''}</IonLabel>
                </IonSegmentButton>
              );
            })}
          </IonSegment>
        </div>
        <Slider
        settings={{
          loop: false,
          initialSlide: selectedTab,
          onSlideChange: (swiper) => this.segmentChanged(swiper.activeIndex),
          onSwiper: (swiper) => this.setState({swiper})

        }
        }>
        {tabs.map((tab, index) => {
          return (
            <Slide key={index + 'slide'} className={'slide-' + (index + 1)}>
              <IonContent>{tab.tabContent || null}</IonContent>
            </Slide>
          );
        })}
      </Slider>
        {displayFooter &&
          displayFooter === 'balance' &&
          isDefined(displayFooterTab) &&
          displayFooterTab + '' === selectedTab + '' ? (
          <div className="loyalty-bar">
            <div>
              <div className="flex-col-wrapper">
                <img src={redeemIcon} alt=' ' />
              </div>
            </div>
            <div>
              <Subtitle className='roboto-slab'>{__('Loyalty Balance')}</Subtitle>
            </div>
            <div>
              <StrongText color="primary" className=" loyalty-balance thiner-text roboto-slab">
                {available_balance} {__('Points')}
              </StrongText>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation(SwipableTabs);
