import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonIcon, IonGrid, IonCol, IonRow, IonRippleEffect } from '@ionic/react';
import { logoTwitter, logoFacebook, logoLinkedin, logoInstagram, logoTiktok, browsersOutline, globe } from 'ionicons/icons';
import { getSocials } from '../../store/actions';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Title, SmallText, Spacer, NormalText, Subtitle } from '../../components/common';
import { ucWords, isDefined, isEmptyObject } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import './index.css';
import NoData from '../../components/noData';

const openExternalLink = (url) => window.open(url, '_system', 'location=yes');

class Social extends Component {
  componentDidMount() {
    this.props.dispatch(getSocials());
  }

  parseSocialItem(key = '', value = '') {
    let label = key;
    let icon = key;
    let link = value;
    let uppercasedKey = key
      .split('')
      .map((char, i) => (i === 0 ? char.toUpperCase() : char))
      .join('');
    switch (uppercasedKey) {
      case 'Facebook':
        icon = logoFacebook;
        break;
      case 'Twitter':
        icon = logoTwitter;
        break;
      case 'Instagram':
        icon = logoInstagram;
        break;
      case 'Linkedin':
        icon = logoLinkedin;
        break;
      case 'TikTok':
        icon = logoTiktok;
        break;
      default:
        icon = globe;
        break;
    }
    return {
      link,
      icon,
      label,
    };
  }

  render() {
    const { __, social } = this.props;
    return (
      <Loading>
        <Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Social Media')}>
          <div className="header-wrapper">
            <Title>{__('Social Media')}</Title>
            <NormalText  >
              {__('Get the latest info and offers by following us on our social media channels')}
            </NormalText>
          </div>
          <Spacer size={2} />
          <div className="frm-wrapper">
            {isDefined(social) && !isEmptyObject(social) ? (
              <>
                {Object.keys(social || {}).map((key, index) => {
                  const value = social[key];
                  const { link, icon, label } = this.parseSocialItem(key, value);
                  const capitalizeLabel = ucWords(label);
                  return (
                    <div key={'soc-item-' + index} >
                      <div
                        className="square ion-activatable social-name"
                        onClick={() => openExternalLink(link)}
                      >
                        <div className="soc-icon">
                          <IonIcon color='primary' icon={icon} />
                        </div>
                        <Subtitle className="uppercase">
                          {__(capitalizeLabel)}
                        </Subtitle>
                        <IonRippleEffect></IonRippleEffect>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <NoData label={__('No social networks')} />
            )}
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { social } = state.common;
  return {
    social,
  };
};

export default connect(stateToProps)(withTranslation(Social));
